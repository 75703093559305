<template>
  <div>
    <vue-headroom @top="top = true"
                  @not-top="top = false"
                  @pin="pinned = true"
                  @unpin="pinned = false"
                  class="fixed-top">
      <b-navbar class="ym-navbar" toggleable="lg" variant="Dark" type="dark" fixed="top">
        <b-container>
          <b-navbar-brand :href="localePath('/')">
            <b-img :src="logoPath" height="68">
            </b-img>
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav class="text-center text-xl-right">
            <b-navbar-nav class="ml-auto mr-xl-5 mb-5 mb-xl-0">
              <b-nav-item :href="nav.url" v-for="(nav, key) in menu" v-bind:data="nav" v-bind:key="key">
                {{ translate(nav.name) }}
              </b-nav-item>
            </b-navbar-nav>

            <b-button v-if="this.$auth.loggedIn === false" variant="outline-light" size="sm" pill class="nav-btn"
                      :href="localePath('/login')">{{ $t('menu.login') }}
            </b-button>
            <!-- <b-button v-else variant="outline-secondary" size="sm" pill class="nav-btn" @click="backPanel()">
              {{ $t('menu.mi_panel') }}
            </b-button> -->
          </b-collapse>
        </b-container>
      </b-navbar>
    </vue-headroom>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: 'ym-navbar',
  data() {
    return {
      top: true,
      pinned: false,
      open: false,
      showCollapse: false,
      formattedAgency: {
        id: 0,
        name: null,
        email: null,
        age: 0,
        phone: null,
        phoneCode: null,
        languageCode: null,
        active: false,
        socialMedia: [
          {
            id: 0,
            url: null,
            active: false,
            socialMediaId: 0,
            agencyUserId: 0,
            socialMediaModel: {
              id: 0,
              name: null,
              description: null,
              imageUrl: null,
              active: false
            }
          }
        ],
        phones: [
          {
            id: 0,
            description: null,
            phone: null,
            active: false,
            phoneId: 0,
            agencyUserId: 0,
            phoneModel: {
              id: 0,
              name: null,
              description: null,
              active: false
            }
          }
        ],
        bankAccounts: [
          {
            id: 0,
            businessName: null,
            bankName: null,
            branchName: null,
            accountNumber: null,
            cardNumber: null,
            clabeNumber: null,
            active: false,
            bankAccountId: 0,
            agencyUserId: 0,
            bankAccount: {
              id: 0,
              name: null,
              description: null,
              active: false
            }
          }
        ],
        emails: [
          {
            id: 0,
            email: null,
            active: false,
            emailId: 0,
            agencyUserId: 0,
            emailModel: {
              id: 0,
              name: null,
              description: null,
              active: false
            }
          }
        ],
        profile: {
          id: 0,
          description: null,
          openingHours: '',
          profileImgUrl: null,
          bannerImgUrl: null,
          address: '',
          addressUrl: null,
          agencyUserId: 0
        },
        paymentProcessors: [
          {
            id: 0,
            name: null,
            description: null,
            email: null,
            active: false,
            agencyId: 0,
            createdAt: null,
            updatedAt: null,
            paymentMethods: {
              data: [
                {
                  id: 0,
                  name: null,
                  description: null,
                  paymentProcessorId: 0,
                  active: false,
                  createdAt: null,
                  updatedAt: null,
                  paymentProcessor: {
                    data: {
                      id: 0,
                      name: null,
                      description: null,
                      email: null,
                      active: false,
                      agency_id: 0,
                      created_at: null,
                      updated_at: null
                    }
                  }
                }
              ]
            }
          }
        ]
      },
    };
  },
  computed: {
    route: function () {
      return this.getRoute();
    },
    logoPath: function () {
      const _agency = this.formattedAgency;
      console.log(_agency)
      return _agency !== null ? _agency.profile.profileImgUrl : "/assets/images/logo-white.png";
    },
    isEventRoute: function () {
      const eventPattern = /^\/eventos\/PLS-[A-Z0-9-]+(\/.*)?$/i;
      return eventPattern.test(this.$nuxt.$route.fullPath);
    },
    menu: function () {
      const routeName = this.$nuxt.$route.name ?? '';
      const route = routeName.substring(0, routeName.length - 5);
      const codeEvent = this.$nuxt.$route.params.id;

      if (route === 'eventos-id' || route === 'eventos-id-ceremonia' || route === 'eventos-id-cronograma') {
        return [
          {
            name: 'Evento',
            url: this.localePath({name: 'eventos-id', params: {id: codeEvent}}),
          },
          {
            name: 'Cronograma',
            url: this.localePath({name: 'eventos-id-cronograma', params: {id: codeEvent}}),
          },
        ];
      } else {
        return [
          {
            name: 'Contacto',
            url: 'https://travele.typeform.com/to/BF0SIIBr',
          },
        ];
      }
    },
    ...mapState(['generalEvent', 'agency', 'eventAgency', 'logged', 'type']),
    ...mapGetters(['isAdmin', 'isAgency', 'isOrganizer', 'isAttendee']),
  },
  mounted() {
    window.addEventListener('resize', this.__resize);
  },
  created() {
    this.setAgency();
  },
  destroyed() {
    window.removeEventListener('resize', this.__resize);
  },
  methods: {
    // TODO check this seem to be and old way to get the current route
    getRoute() {
      return this.$nuxt.$route.name.split('-')[0]
    },
    translate(menu) {
      let lowerCase = menu.toLowerCase();
      let text = lowerCase.replace(/ /g, "_");

      let menus = {
        historias_felices: this.$t('menu.historias'),
        nuestra_metodologia: this.$t('menu.metodologia'),
        luna_de_miel: this.$t('menu.luna'),
        eventos: this.$t('menu.eventos'),
        paquetes: this.$t('menu.paquetes'),
        centro_de_ayuda: this.$t('menu.ayuda'),
        contacto: this.$t('menu.contacto'),
        evento: this.$t('menu.evento.evento'),
        galeria: this.$t('menu.evento.galeria'),
        ceremonia: this.$t('menu.evento.ceremonia'),
        cronograma: this.$t('menu.evento.cronograma'),
      };
      return menus[text];
    },
    async setAgency() {
      if (this.isEventRoute) {
        this.formattedAgency = this.generalEvent.agency;
      } else if (this.$store.state.auth.loggedIn) {
        if (this.isAdmin) {
          this.formattedAgency = null;
        } else if (this.isAgency) {
          this.formattedAgency = await this.getAgency(this.$store.state.currentEvent.idAgency);
        } else if (this.isAttendee) {
          this.formattedAgency = await this.getAgency(this.$store.state.currentEvent.idagency);
        } else if (this.isOrganizer) {
          this.formattedAgency = await this.getAgency(this.$store.state.currentAgency.id);
        } else if (this.isAgency){
          this.formattedAgency = await this.getAgency(this.$store.state.currentAgency.id);
        }
      } else {
        this.formattedAgency = null;
      }
    },
    formatAgency(agency) {
      this.formattedAgency = {
        id: agency.idAgency,
        name: agency.name,
        phone: agency.phone1,
        email: agency.emailContact,
        openingHours: agency.schedules,
        address: agency.address,
        logoImgUrl: agency.logo,
        additionalEmails: [
          {name: 'Reply', email: agency.emailReply},
          {name: 'Support', email: agency.emailSupport}
        ],
        additionalPhones: [
          {name: '', phone: agency.phone2},
          {name: '', phone: agency.phone3}
        ]
      };
    },
    async getAgency(agencyId) {
      try {
        const response = await this.$axios.get(`api/agency/${agencyId}/bank-data`);
        return response.data;
      } catch (error) {
        this.options.message = error.response.data.error;
        this.options.show = true;
      }
    },
  },
  watch: {
    '$route'() {
      this.showCollapse = false;
    }
  }
};
</script>

<style lang="scss" scoped>

.ym-navbar {
  background: -o-linear-gradient(top, transparentize($dark, 0.4), transparent);
  background: linear-gradient(to bottom, transparentize($dark, 0.4), transparent);
  //background: linear-gradient(to bottom, black, white);
  -webkit-transition: $transition-base;
  -o-transition: $transition-base;
  transition: $transition-base;

  .nav-item:not(:last-child) {

    @include media-breakpoint-down(lg) {
      margin-bottom: 1rem;
    }

    @include media-breakpoint-up(xl) {
      margin-right: 1.5rem;
    }
  }

  .nav-link {
    text-align: center;
  }
}

.bg-light-blur {
  -webkit-backdrop-filter: blur(5px) saturate(180%);
  backdrop-filter: blur(5px) saturate(180%);
  background: rgba(250, 250, 251, 0.37);
  color: black;
}

.bg-dark-blur {
  -webkit-backdrop-filter: blur(5px) saturate(180%);
  backdrop-filter: blur(5px) saturate(180%);
  background: rgba(143, 143, 143, 0.226);
  color: black;
}

@include media-breakpoint-down(sm) {
  .ym-navbar {
    background: -webkit-gradient(linear, left top, left bottom, from(transparentize(#4B6C78, 0.1)), to(transparentize(#9FBAC4, 0.1)));
    background: -o-linear-gradient(top, transparentize(#4B6C78, 0.1), transparentize(#9FBAC4, 0.1));
    background: linear-gradient(180deg, transparentize(#4B6C78, 0.1), transparentize(#9FBAC4, 0.1));
    -webkit-transition: $transition-base;
    -o-transition: $transition-base;
    transition: $transition-base;
  }
  .btn-outline-secondary {
    color: #c60568;
    border-color: #c60568;
  }
}
</style>
