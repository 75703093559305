<template>
  <div>
    <Loader :loader="loader"></Loader>
    <div class="d-flex">
      <!-- Sidebar-->
      <div class="border-end" id="sidebar-wrapper">
        <div v-if="isAdmin && currentAgencyForAdmin" class="sidebar-heading shadow-sm text-center">
          <b-img
              :src="currentAgencyForAdmin.profile.profileImgUrl"
              class="img-fluid img-thumbnail"
              style="object-fit: cover; width: 230px; height: 50px"
          />
        </div>
        <div v-if="(isAgency || isAgencyUser || isPayment || isOrganizer) && agency"
             class="sidebar-heading shadow-sm text-center">
          <b-img
              :src="agency.profile.profileImgUrl"
              class="img-fluid img-thumbnail"
              style="object-fit: cover; width: 230px; height: 50px"
          />
        </div>
        <template v-if="!isAttendee">
          <div class="list-group">
            <!-- AGENCY LIST -->
            <div v-if="isAdmin && agenciesList" class="navbar navbar-nav pr-0">
              <a class="d-flex align-items-center list-group-item-action p-3" v-b-toggle.collapse-agencies>
                <font-awesome-icon :icon="['fas', 'list']" class="mr-1"/>
                {{ $t("admin_master.navbar.side.agencias") }}
                <font-awesome-icon :icon="['fas', 'chevron-down']" class="ml-auto"/>
              </a>
              <b-collapse id="collapse-agencies" class="mt-2">
                <b-card no-body class="dropdown-menu dropdown-menu-end p-2 shadow">
                  <a v-for="(agency, index) in agenciesList" :key="index"
                     class="dropdown-item pl-2 pr-2 shadow-sm rounded a-click" @click="setAgency(agency, 'newAgency')"
                     style="white-space: normal">
                    <div class="p-2">
                      <strong class="m-0 ">{{ agency.name }}</strong>
                    </div>
                  </a>
                </b-card>
              </b-collapse>
            </div>
            <hr class="hr-text" :data-content="$t('admin_master.navbar.side.separador_agencias')" width="100%">
            <!-- SELECTED AGENCY -->
            <b-row v-if="isAdmin && currentAgencyForAdmin"
                   class="d-flex justify-between align-items-center p-3 m-0 shadow-sm">

              <b-col>
                <b style="font-size: 16px">{{
                    currentAgencyForAdmin.name
                  }}</b>
              </b-col>
            </b-row>
            <b-row v-if="(isAgency || isAgencyUser || isOrganizer || isPayment || isWeddingPlanner) && agency"
                   class="d-flex justify-content-center align-items-center p-3 m-0 shadow-sm">
              <b-col class="text-center">
                <b style="font-size: 16px">{{ agency.name }}</b>
              </b-col>
            </b-row>
          </div>
          <!-- EVENT LIST -->
          <div v-if="eventsList" class="navbar navbar-nav pr-0">
            <a class="d-flex align-items-center list-group-item-action p-3" v-b-toggle.collapse-events>
              <font-awesome-icon :icon="['fas', 'list']" class="mr-1"/>
              {{ $t("admin_master.navbar.side.evento_lista") }}
              <font-awesome-icon :icon="['fas', 'chevron-down']" class="ml-auto"/>
            </a>
            <b-collapse id="collapse-events" class="mt-2">
              <b-card no-body class="dropdown-menu dropdown-menu-end p-2 shadow">
                <a v-for="(event, index) in eventsList" :key="index"
                   class="dropdown-item pl-2 pr-2 shadow-sm rounded a-click" @click="setEvent(event, 'newEvent')"
                   style="white-space: normal">
                  <div class="p-2">
                    <strong class="m-0 ">{{ event.eventName }}</strong>
                    <br>
                    <span class="m-0">{{ formatDate(event) }}</span>
                  </div>
                </a>
              </b-card>
            </b-collapse>
          </div>
        </template>
        <div class="list-group">
          <hr class="hr-text" :data-content="$t('admin_master.navbar.side.separador_evento')" width="100%">
          <!-- CATEGORÍA -->
          <b-row v-if="currentEvent" class="d-flex justify-between align-items-center p-3 m-0 shadow-sm">
            <b-img
                :src="currentEvent.imgCoverCircle"
                rounded="circle"
                width="50px"
                height="50px"
            />
            <b-col>
              <b style="font-size: 16px">{{
                  currentEvent.eventName
                }}</b>
              <p class="m-0">{{ formatDate(currentEvent) }}</p>
            </b-col>
          </b-row>
          <!-- NOTIFICATIONS -->
          <template v-if="isAdmin|| isAgency || isAgencyUser || isWeddingPlanner">
            <router-link
                class="d-flex align-items-center list-group-item-action p-3"
                :to="localePath('/adminmaster/Notificaciones')"
            >
              <font-awesome-icon :icon="['fas', 'bell']" class="mr-1"/>
              {{ $t("admin_master.navbar.side.notificacion") }}
              <span v-if="notificationsQuantity > 0" class="notification__icon ml-auto">
                {{ notificationsQuantity }}
              </span>
            </router-link>
          </template>

          <template>
            <router-link
                v-if="isAdmin || isAgency || isAgencyUser || isWeddingPlanner || isPayment"
                class="list-group-item-action p-3"
                :to="localePath('/adminmaster/PanelAdministracion')"
                @click.native="toggleDashboard(true)"
            >
              <font-awesome-icon :icon="['fas', 'desktop']"/>
              {{ $t("admin_master.navbar.side.dashboard") }}
            </router-link>
            <router-link
                v-if="isAdmin|| isAgency || isAgencyUser || isWeddingPlanner || isOrganizer"
                class="list-group-item-action p-3"
                :to="localePath('/adminmaster/Transporte')"
                @click.native="toggleDashboard(false)"
            >
              <font-awesome-icon :icon="['fas', 'plane']"/>
              {{ $t("admin_master.navbar.side.transporte") }}
            </router-link>
            <router-link
                v-if="isAdmin|| isAgency || isAgencyUser || isWeddingPlanner"
                class="list-group-item-action p-3"
                :to="localePath('/adminmaster/CentroLlamadas')"
                @click.native="toggleDashboard(false)"
            >
              <font-awesome-icon :icon="['fas', 'phone-alt']"/>
              {{ $t("admin_master.navbar.side.call_center") }}
            </router-link>
            <router-link
                v-if="isAdmin|| isAgency || isAgencyUser || isWeddingPlanner || isPayment"
                class="list-group-item-action p-3"
                :to="localePath('/adminmaster/CuentaMaestra')"
                @click.native="toggleDashboard(false)"
            >
              <font-awesome-icon :icon="['fas', 'id-card']"/>
              {{ $t("admin_master.navbar.side.cuenta_maestra") }}
            </router-link>
            <router-link v-if="isAdmin || isAgency"
                         class="list-group-item-action p-3"
                         :to="localePath('/adminmaster/CuentaBancaria')"
                         @click.native="toggleDashboard(false)"
            >
              <font-awesome-icon :icon="['fas', 'university']"/>
              {{ $t("admin_master.navbar.side.cuenta_bancaria") }}
            </router-link>
            <router-link
                v-if="isAdmin|| isAgency || isAgencyUser || isWeddingPlanner || isPayment"
                class="list-group-item-action p-3"
                :to="localePath('/adminmaster/Perfil')"
                @click.native="toggleDashboard(false)"
            >
              <font-awesome-icon :icon="['fas', 'user']"/>
              {{ $t("admin_master.navbar.side.perfil") }}
            </router-link>
          </template>
          <hr class="hr-text" width="100%">
          <a class="list-group-item-action p-3 a-click" @click="logOut()">
            <font-awesome-icon :icon="['fas', 'times-circle']"/>
            <b>{{ $t("admin_master.navbar.side.salir") }}</b>
          </a>
        </div>
      </div>
      <!-- Page content wrapper-->
      <div id="page-content-wrapper">
        <!-- Top navigation-->
        <b-navbar v-if="!dashboardNavigation" toggleable="lg" type="light" variant="light" class="shadow-sm">

          <b-navbar-toggle target="nav-collapse" class="ml-auto"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav class="text-center">
            <b-navbar-nav class="m-auto" color>

              <b-nav-item @click="setEvent(currentEvent)">
                <router-link class="text-decoration-none text-dark" :to="localePath('/adminmaster/Evento')">
                  {{ $t("admin_master.navbar.head.evento") }}
                </router-link>
              </b-nav-item>
              <b-nav-item @click="setEvent(currentEvent)">
                <router-link class="text-decoration-none text-dark"
                             :to="localePath('/adminmaster/ConfigurarCategorias')">
                  Categorias
                </router-link>
              </b-nav-item>
              <b-nav-item @click="removeItem()">
                <router-link class="text-decoration-none text-dark" :to="localePath('/adminmaster/Cronograma')">
                  {{ $t("admin_master.navbar.head.cronograma") }}
                </router-link>
              </b-nav-item>

              <b-nav-item>
                <router-link class="text-decoration-none text-dark" :to="localePath('/adminmaster/Invitados')">
                  {{ $t("admin_master.navbar.head.invitado") }}
                </router-link>
              </b-nav-item>

              <b-nav-item>
                <router-link class="text-decoration-none text-dark" :to="localePath('/adminmaster/Asistentes')">
                  {{ $t("admin_master.navbar.head.asistente") }}
                </router-link>
              </b-nav-item>

              <b-nav-item>
                <router-link class="text-decoration-none text-dark" :to="localePath('/adminmaster/CuentaMaestraAdmin')">
                  {{ $t("admin_master.navbar.head.cuenta_maestra") }}
                </router-link>
              </b-nav-item>

              <b-nav-item>
                <router-link class="text-decoration-none text-dark"
                             :to="localePath('/adminmaster/NotificacionesLista')">
                  {{ $t("admin_master.navbar.head.notificacion") }}
                </router-link>
              </b-nav-item>

            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
        <!-- Top navigation when we are in Dashboard-->
        <b-navbar v-if="dashboardNavigation" toggleable="lg" type="light" variant="light" class="shadow-sm">
          <b-navbar-toggle target="nav-collapse" class="ml-auto"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav class="text-center">
            <b-navbar-nav class="m-auto">

              <!-- Opción Hoteles con un icono -->
              <b-nav-item>
                <router-link v-if="isAdmin" class="text-decoration-none text-dark d-flex align-items-center"
                             :to="localePath('/adminmaster/HotelAdministrar')">
                  <font-awesome-icon :icon="['fas', 'hotel']" class="mr-2"/> <!-- Icono para Hoteles -->
                  Hoteles
                </router-link>
              </b-nav-item>

              <!-- Opción Agency Manager con un icono -->
              <b-nav-item>
                <router-link v-if="isAdmin || isAgency" class="text-decoration-none text-dark d-flex align-items-center"
                             :to="localePath('/adminmaster/AgenciaAdministrar')">
                  <font-awesome-icon :icon="['fas', 'suitcase']" class="mr-2"/> <!-- Icono para Agency Manager -->
                  Agency Manager
                </router-link>
              </b-nav-item>

              <!-- Línea divisoria o margen para separar visualmente -->
              <b-nav-item v-if="isAdmin || isAgency" class="mx-2">
                <hr class="my-auto" style="border-color: #ccc; width: 1px; height: 30px;">
              </b-nav-item>

              <!-- Dropdown de Herramientas con un icono -->
              <b-nav-item-dropdown v-if="isAdmin || isAgency">
                <template #button-content>
                  Herramientas
                  <font-awesome-icon :icon="['fas', 'cog']" class="ml-2"/>
                </template>

                <!-- Opción Roles de usuario con icono -->
                <b-nav-item>
                  <router-link tag="b-dropdown-item" :to="localePath('/adminmaster/RolesUsuario')">
                    <font-awesome-icon :icon="['fas', 'users']" class="mr-2"/> <!-- Icono para Roles -->
                    Roles de usuario
                  </router-link>
                </b-nav-item>

                <!-- Opción Permisos con icono, solo si es Admin -->
                <b-dropdown-item @click="navigateToPermissions" v-if="isAdmin">
                  <font-awesome-icon :icon="['fas', 'key']" class="mr-2"/> <!-- Icono para Permisos -->
                  Permisos
                </b-dropdown-item>
              </b-nav-item-dropdown>

            </b-navbar-nav>
          </b-collapse>
        </b-navbar>

        <!-- Page content-->
        <main class="mt-2">
          <slot name="navBarContainer"/>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "~/plugins/axios";
import Loader from "@/components/Loader";
import {mapGetters, mapActions} from "vuex";
import moment from "moment";

export default {
  name: "NavBar",
  components: {Loader},
  data() {
    return {
      loader: false,
      eventsList: null,
      agenciesList: {},
      generalEvent: [],
      agency: {
        id: null,
        name: '',
        email: '',
        age: 0,
        phone: '',
        phones: [
          {
            id: null,
            phone: '',
            phoneModel: {
              id: null,
              name: '',
              description: ''
            }
          },
          {
            id: null,
            phone: '',
            phoneModel: {
              id: null,
              name: '',
              description: ''
            }
          }
        ],
        phoneCode: '',
        languageCode: '',
        active: false,
        socialMedia: [
          {
            id: null,
            url: '',
            active: '',
            socialMediaId: null,
            agencyUserId: null,
            socialMediaModel: {
              id: null,
              name: '',
              description: '',
              imageUrl: '',
              active: '',
            },
          },
        ],
        bankAccount: {},
        emails: [
          {
            id: null,
            email: '',
            emailModel: {
              id: null,
              name: '',
              description: ''
            }
          },
          {
            id: null,
            email: '',
            emailModel: {
              id: null,
              name: '',
              description: ''
            }
          }
        ],
        profile: {}
      },
      currentEvent: null,
      currentAgencyForAdmin: null,
      notificationsQuantity: 0,
    };
  },
  async fetch() {
    if (this.isAdmin) {
      await this.handleAdminFetch();
    }
    if (this.isAgency || this.isAgencyUser || this.isOrganizer || this.isPayment || this.isWeddingPlanner) {
      await this.handleAgencyFetch();
    }
  },
  created() {
  },
  computed: {
    ...mapGetters([
      "isAdmin",
      "isAgency",
      "isAgencyUser",
      "isOrganizer",
      "isPayment",
      "isAttendee",
      "isWeddingPlanner"
    ]),
    eventStartRangeFormatted() {
      return moment(this.eventStartRange, "DD-MM-YYYY").format("DD/MM/YYYY");
    },
    eventEndRangeFormatted() {
      return moment(this.eventEndRange, "DD-MM-YYYY").format("DD/MM/YYYY");
    },
    dashboardNavigation() {
      return this.$store.state.dashboardNavigation;
    },
  },
  watch: {},
  methods: {
    ...mapActions([
      "login_out",
      "setCurrentAgencyForAdmin",
      "setCurrentEventListForAdmin",
      "setCurrentEventList",
      "setCurrentEvent",
      "getCurrentEvent",
      "setCurrentEventForAdmin",
    ]),
    async handleAdminFetch() {
      try {
        await this.getAgencies();
        if (this.$store.state.currentAgencyForAdmin === null) {
          const agencyId = await this.setAgency();
          await this.initializeEventsForAdmin(agencyId);
        } else {
          await this.handleExistingAdminAgency();
        }
        await this.setCurrentEventForAdmin(this.currentEvent);
      } catch (error) {
        console.error("Error en la configuración de la agencia:", error);
      }
    },
    async handleAgencyFetch() {
      await this.getAgency();
      await this.getEvents();
      if (this.$store.state.currentEvent === null) {
        await this.setCurrentEvent(this.eventsList[0]);
      }
      this.loadNotifications();
      this.currentEvent = this.$store.state.currentEvent;
    },
    async handleExistingAdminAgency() {
      this.currentAgencyForAdmin = this.$store.state.currentAgencyForAdmin;
      const agencyUserId = this.currentAgencyForAdmin.id;
      if (this.$store.state.currentEventForAdmin) {

        const eventAgencyConfigId = this.$store.state.currentEventForAdmin.id;
        if (eventAgencyConfigId === agencyUserId) {
          await this.getEventsByAgency(agencyUserId);
          this.currentEvent = this.$store.state.currentEventForAdmin;
        } else {
          await this.initializeEventsForAdmin(agencyUserId);
        }
      } else {
        await this.initializeEventsForAdmin(agencyUserId);
      }
    },
    async initializeEventsForAdmin(agencyId) {
      await this.getEventsByAgency(agencyId);
      await this.setCurrentEventForAdmin();
      this.currentEvent = this.$store.state.currentEventForAdmin;
    },
    async getAgencies() {
      try {
        this.loader = true;
        this.agenciesList = this.$store.state.currentAgencyListForAdmin;
      } catch (error) {
        console.log(error);
        this.loader = false;
      }
    },
    async getEvents() {
      try {
        this.eventsList = this.$store.state.currentEventList;
      } catch (error) {
        console.log(error);
      }
    },
    async setEvent(event, newEvent) {
      this.loader = true;
      try {
        if (this.isAdmin) {
          await this.setCurrentEventForAdmin(event);
          this.currentEvent = this.$store.state.currentEventForAdmin;
        } else if (this.isAgency || this.isAgencyUser || this.isWholesaler || this.isPayment || this.isWeddingPlanner) {
          await this.setCurrentEvent(event);
          this.currentEvent = this.$store.state.currentEvent;
        }
        this.$root.$emit("forceRerender");
        this.loadNotifications();
      } catch (error) {
        console.log(error);
      } finally {
        this.loader = false;
      }
    },
    async setAgency(agency, newAgency) {
      try {
        this.loader = true;
        let agencyId = 0;
        if (agency === undefined) {
          await this.setCurrentAgencyForAdmin();
          agencyId = this.$store.state.currentAgencyForAdmin.id;
        } else {
          agencyId = agency.id;
          await this.getEventsByAgency(agencyId);
          await this.setCurrentAgencyForAdmin(agency);
          await this.setCurrentEventForAdmin();
        }
        this.currentEvent = this.$store.state.currentEventForAdmin;
        this.currentAgencyForAdmin = agency;
        this.$root.$emit("forceRerender");
        this.loadNotifications();
        return agencyId;
      } catch (error) {
        console.log(error);
        return null;
      } finally {
        this.loader = false;
      }
    },
    async getEventsByAgency(agencyUserId) {
      this.loader = true;
      try {
        const events = await this.$axios.get(`api/v1/agency/${agencyUserId}/events`);
        this.setCurrentEventListForAdmin(events.data);
        this.eventsList = events.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loader = false;
      }
    },
    loadNotifications() {
      if (process.browser) {
        if (this.isAdmin || this.isAgency || this.isAgencyUser || this.isWeddingPlanner) {
          const agencyId = this.isAdmin
              ? this.$store.state.currentAgencyForAdmin.id
              : this.$store.state.currentAgency.id;
          this.$axios
              .get(`api/notifications/agency/${agencyId}?page=1`)
              .then((response) => {
                const notificationsResponse = response.data.data.data;
                if (notificationsResponse) {
                  const filteredNotifications = notificationsResponse.filter(
                      (element) => element.data[0].type !== "UNKNOWN" && element.data[0].status !== "Off"
                  );
                  this.notificationsQuantity = filteredNotifications.length;
                }
              })
              .catch((error) => {
                this.$notify({
                  group: "foo",
                  type: "error",
                  title: this.$t("snotify.title.error"),
                  text: this.$t("snotify.mensaje.error"),
                });
              });
        }
      }
    },
    logOut() {
      this.loader = true;
      this.login_out();
    },
    removeItem() {
      sessionStorage.removeItem("activity");
    },
    formatDate(date) {
      const {eventDate, range_event_start, range_event_end} = date;

      if (!eventDate || !range_event_start || !range_event_end) {
        return "Fecha no válida";
      }

      const inputFormat = "YYYY-MM-DD";
      const outputFormat = "DD-MM-YYYY";

      if (eventDate === "1970-01-01") {
        if (moment(range_event_start, inputFormat).isValid() && moment(range_event_end, inputFormat).isValid()) {
          return `${moment(range_event_start, inputFormat).format(outputFormat)} - ${moment(range_event_end, inputFormat).format(outputFormat)}`;
        } else {
          return "Rango de fechas no válido";
        }
      } else if (moment(eventDate, inputFormat).isValid()) {
        return moment(eventDate, inputFormat).format(outputFormat);
      } else {
        return "Fecha no válida";
      }
    },
    toggleDashboard(value) {
      this.$store.commit("setDashboardNavigation", value);
    },
    navigateToPermissions() {
      this.$router.push({path: "/adminmaster/Permisos"});
    },
    cleanURL() {
      const cleanUrl = location.href.match(/^.+(?=\?)/g);
      window.history.replaceState(null, null, cleanUrl ? cleanUrl[0] : location.href);
    },
    async getAgency() {
      this.loader = true;
      let agencyId = this.$store.state.currentAgency.id;
      try {
        const response = await this.$axios.get(`api/agency/${agencyId}/bank-data`);
        this.agency = response.data;
        this.loader = false;
      } catch (error) {
        this.options.message = error.response.data.error;
        this.options.show = true;
        this.loader = false;
      }
      this.loader = false;
    },
  },
};

</script>

<style scoped lang="scss">
.border-end {
  border-right: 1px solid #dee2e6 !important;
  background-color: #544762;
  color: #dee2e6;
}

#sidebar-wrapper {
  min-height: 100vh;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.2rem 0;
  font-size: 1.2rem;
  background-color: rgb(248, 249, 250);
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #dee2e6;
  text-align: inherit;
}

.list-group-item-action:hover {
  z-index: 1;
  color: black;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown__sidebar {
  display: block;
  padding: 0.5rem 1rem;
  color: #dee2e6;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out;
}

.dropdown-menu {
  max-width: 14rem;
  max-height: 21rem;
  overflow: auto;
}

.notification__icon {
  background-color: red;
  border-radius: 5px;
  padding: 1px 6px 1px 6px;
}

.list-hover:hover {
  background-color: #f8f9fa;
  color: #544762;
}

.a-click {
  cursor: pointer;
}

.navbar-item {
  color: #4b6c78;
  text-decoration: none;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1em;
  opacity: .5;

  &:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;
    line-height: 1em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: #dee2e6;
    background-color: #544762;
  }
}
</style>
