import axios from "~/plugins/axios";
import createPersistedState from "vuex-persistedstate";
import * as Cookies from "js-cookie";

export const GRL_STATUS = {
    STATUS_ON_HOLD: "Espera",
    STATUS_ON: "On",
    STATUS_OFF: "Off",
    STATUS_PAID_OUT: "paid",
};

export const ROLES = {

    // **************** OLD ROLES ****************
    ROLE_SUPPORT: "Soporte",
    // **************** NEW ROLES FROM API ****************
    ROLE_GUEST: "guest",
    ROLE_ADMIN: "admin",
    ROLE_AGENCY: "agency",
    ROLE_PAYMENT: "payment",
    ROLE_ATTENDEE: "attendee",
    ROLE_ORGANIZER: "organizer",
    ROLE_WHOLESALER: "wholesaler",
    ROLE_AGENCY_USER: "agencyUser",
    ROLE_RESERVATION: "reservation",
    ROLE_WEDDING_PLANNER: "weddingPlanner",
};

export const TYPE_NOTIFICATIONS = {
    TYPE_CONTACT_FROM_ORGANIZER: "CONTACT_ORG",
    TYPE_CONTACT_FROM_ATTENDEE: "CONTACT_ATTE",
    TYPE_RESERVATION_TO_APPROVE: "RSVN_TO_APPV",
    TYPE_RESERVATION_TO_CANCEL: "RSVN_TO_CNX",
    TYPE_TRANSPORT_QUOTATION: "TP_QT",
    TYPE_PAYMENT_VOUCHER_TO_APPROVE: "PYMT_VO_TO_APPV",
    TYPE_UNKNOWN: "UNKNOWN",
    // normal
    OLD_TYPE_VOUCHER: "Voucher",
    OLD_TYPE_PAYMENT: "abonoPaquete",
    OLD_TYPE_TRANSPORT: "transporte",
    OLD_TYPE_CALL_TO_RESERVATION: "llamarReserva",
    OLD_TYPE_APPROVE: "aprobar",
};

export const RESET_KEY_RESPONSE = {
    INVALID_RESET_KEY: "IRK", // the key is outdated
    NOT_FOUND_RESET_KEY: "RKNF", // the key doesn't exist in the system
    PASSWORD_UPDATED: "PUU", // user found by reset key and updated
};

export const getters = {
    isAdmin: (state, commit, rootState) => {
        return (
            rootState.auth.loggedIn &&
            rootState.auth.user.role?.name?.includes(state.ROLES.ROLE_ADMIN)
        );
    },
    isAgency: (state, commit, rootState) => {
        return (
            rootState.auth.loggedIn &&
            rootState.auth.user.role?.name?.includes(state.ROLES.ROLE_AGENCY)
        );
    },
    isAgencyUser: (state, commit, rootState) => {
        return (
            rootState.auth.loggedIn &&
            rootState.auth.user.role?.name?.includes(state.ROLES.ROLE_AGENCY_USER)
        );
    },
    isOrganizer: (state, commit, rootState) => {
        return (
            rootState.auth.loggedIn &&
            rootState.auth.user.role?.name?.includes(state.ROLES.ROLE_ORGANIZER)
        );
    },
    isAttendee: (state, commit, rootState) => {
        return (
            rootState.auth.loggedIn && (
                rootState.auth.user.role?.name?.includes(state.ROLES.ROLE_ATTENDEE)
            )
        );
    },
    isPayment: (state, commit, rootState) => {
        return (
            rootState.auth.loggedIn &&
            rootState.auth.user.role?.name?.includes(state.ROLES.ROLE_PAYMENT)
        );
    },

    isWeddingPlanner: (state, commit, rootState) => {
        return (
            rootState.auth.loggedIn &&
            rootState.auth.user.role?.name?.includes(state.ROLES.ROLE_WEDDING_PLANNER)
        );
    },
    isGuest: (state, commit, rootState) => {
        return (
            rootState.auth.loggedIn &&
            rootState.auth.user.role?.name?.includes(state.ROLES.ROLE_GUEST)
        );
    },
    isWholesaler: (state, commit, rootState) => {
        return (
            rootState.auth.loggedIn &&
            rootState.auth.user.role?.name?.includes(state.ROLES.ROLE_WHOLESALER)
        );
    },
    sessionLanguage: (state, commit, rootState) => {
        return navigator.language || navigator.userLanguage;
    },
};

export const state = () => ({
    /////////////////// RESERVATION PROCESS ////////////////
    reservationProcess: {
        roomsConfiguration: {},
    },
    eventAgency: null, // merge with agency
    agency: "",
    event: null,
    /////////////////// END ADMIN CONSOLE //////////////////
    currentAgencyListForAdmin: null,
    currentAgencyForAdmin: null,
    currentEventListForAdmin: null,
    currentEventForAdmin: null,
    /////////////////// END AGENCY CONSOLE //////////////////
    currentAgency: null, //agency selected in new agency console
    currentEvent: null, //event selected in new agency console
    currentEventList: null, //event list selected in new agency console
    /////////////////// END AGENCY CONSOLE //////////////////
    generalEvent: null, // merge with event
    session: null,
    type: null, // will be deprecated use auth
    logged: false, // will be deprecated use auth
    error: null,
    ios: ["iPad", "iPhone"],
    ROLES: ROLES,
    TYPE_NOTIFICATIONS: TYPE_NOTIFICATIONS,
    GRL_STATUS: GRL_STATUS,
    RESET_KEY_RESPONSE: RESET_KEY_RESPONSE,
    dashboardNavigation: false,
});

export const mutations = {
    //RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION,
    setEventAgency(state, payload) {
        state.eventAgency = payload;
    },
    setAgency(state, payload) {
        state.agency = payload;
    },
    setEvent(state, payload) {
        state.generalEvent = payload;
        state.event = payload;
    },
    /////////////////// RESERVATION PROCESS ////////////
    setRoomsConfiguration(state, rooms) {
        state.reservationProcess.roomsConfiguration = rooms;
    },
    /////////////////// ADMIN CONSOLE //////////////////
    setCurrentAdmin(state, payload) {
        state.currentAdmin = payload;
    },
    setCurrentAgencyListForAdmin(state, payload) {
        state.currentAgencyListForAdmin = payload;
    },
    setCurrentEventListForAdmin(state, payload) {
        state.currentEventListForAdmin = payload;
    },
    setCurrentAgencyForAdmin(state, payload) {
        state.currentAgencyForAdmin = payload;
    },
    setCurrentEventForAdmin(state, payload) {
        state.currentEventForAdmin = payload;
    },
    /////////////////// AGENCY CONSOLE //////////////////
    setCurrentAgency(state, payload) {
        state.currentAgency = payload;
    },
    setCurrentEventList(state, payload) {
        state.currentEventList = payload;
    },
    setCurrentEvent(state, payload) {
        state.currentEvent = payload;
    },
    /////////////////// END AGENCY CONSOLE //////////////////
    setSession(state, payload) {
        state.session = payload;
    },
    setType(state, payload) {
        state.type = payload;
    },
    setLogged(state, payload) {
        state.logged = payload;
    },
    setError(state, payload) {
        state.error = payload;
    },
    setDashboardNavigation(state, payload) {
        state.dashboardNavigation = payload;
    }
};

const processLogin = (commit, response, _this, payload) => {
    response
        .then((result) => {
            const authenticatedUser = result.data.authenticated;
            const entity = result.data.data;

            /*@deprecated this is the old implementation use this.$auth object*/
            commit("setSession", entity);
            commit("setLogged", true);
            commit("setError", null);

            const oldRedirect = _this.$auth.options.redirect;
            _this.$auth.options.redirect = false;
            _this.$auth.setUser(authenticatedUser);
            _this.$auth.options.rewriteRedirects = oldRedirect;

            // **************** RESERVATION LOGGED ****************
            if (authenticatedUser.role.name?.includes(ROLES.ROLE_ATTENDEE)) {
                _this.dispatch('getAgencyByLoggedUser')
                    .finally((_) => {
                        _this.$router.push({
                            path: "/adminReservacion",
                            query: payload,
                        });
                    });
            }
            /////////////////// AGENCY CONSOLE //////////////////

            // **************** ADMIN LOGGED ****************
            if (authenticatedUser.role.name?.includes(ROLES.ROLE_ADMIN)) {
                _this.dispatch("setCurrentAdmin", authenticatedUser);
            }
            // **************** AGENCY LOGGED ****************
            if (authenticatedUser.role.name?.includes(ROLES.ROLE_AGENCY)) {
                _this.dispatch("setCurrentAgency", authenticatedUser);
            }
            // **************** AGENCY USER LOGGED ****************
            if (authenticatedUser.role.name?.includes(ROLES.ROLE_AGENCY_USER)) {
                _this.dispatch("setCurrentAgency", authenticatedUser);
            }
            // **************** ORGANIZER LOGGED ****************
            if (authenticatedUser.role.name?.includes(ROLES.ROLE_ORGANIZER)) {
                _this.dispatch("setCurrentAgency", authenticatedUser);
            }
            // **************** PAYMENT LOGGED ****************
            if (authenticatedUser.role.name?.includes(ROLES.ROLE_PAYMENT)) {
                _this.dispatch("setCurrentAgency", authenticatedUser);
            }
            // **************** WEDDING_PLANNER LOGGED ****************
            if (authenticatedUser.role.name?.includes(ROLES.ROLE_WEDDING_PLANNER)) {
                _this.dispatch("setCurrentAgency", authenticatedUser);
            }
            // **************** WHOLESALER LOGGED ****************
            if (authenticatedUser.role.name?.includes(ROLES.ROLE_WHOLESALER)) {
                _this.dispatch("setCurrentAgency", authenticatedUser);
            }
            /////////////////// END AGENCY CONSOLE //////////////////
        })
        .catch((error) => {
            commit("setError", error);
        });
};

export const actions = {
    // validate the codeEvent given, in case of the storage value of the generalEvent has a different key, so we perform
    // the load data.
    async validateCurrentEventOrLoad({commit, state}, codeEventToValidate) {
        return this.dispatch("getEventByCode", codeEventToValidate);
    },
    /////////////////// RESERVATION PROCESS ////////////
    setRoomsConfiguration({commit}, rooms) {
        commit('setRoomsConfiguration', rooms);
    },
    ////////////////////////////////////////////////////
    getEvent({commit}, payload) {
        return axios
            .get(`event/${payload}`)
            .then((response) => {
                return commit("setEvent", response.data.data);

            })
            .catch((error) => {
                console.log(error);
            });
    },
    /////////////////// ADMIN CONSOLE //////////////////
    setCurrentAdmin({commit}, authenticatedUser) {
        //set admin when user logged in new console
        return new Promise((resolve, reject) => {
            this.$axios
                .get(`api/auth/admin`)
                .then((response) => {
                    let admin = null;
                    //when agency logged in
                    if (response.data) {
                        admin = response.data.data;
                        commit("setCurrentAdmin", admin);
                    }

                    if (authenticatedUser && authenticatedUser.role.name.includes(ROLES.ROLE_ADMIN)) {
                        this.dispatch("setCurrentAgencyListForAdmin");
                        //this.dispatch("setCurrentAgencyForAdmin");
                    }
                    /*if (admin === null) {
                        this.dispatch(
                            "getCurrentAdmin",
                            this.state.session.idagency
                        );
                    }*/
                    resolve(admin);
                })
                .catch(reject);
        });
    },
    /////////////////// AGENCY CONSOLE //////////////////
    setCurrentAgency({commit}, authenticatedUser) {
        //set agency when user logged in new console
        return new Promise((resolve, reject) => {
            this.$axios
                .get(`api/auth/agency`)
                .then((response) => {
                    let agency = null;
                    //when agency logged in
                    if (response.data?.data) {
                        agency = response.data.data;

                        commit("setCurrentAgency", agency);

                        if (authenticatedUser && authenticatedUser.role.name?.includes(ROLES.ROLE_AGENCY)) {
                            this.dispatch("setCurrentEventList");
                        }
                        if (authenticatedUser && authenticatedUser.role.name?.includes(ROLES.ROLE_AGENCY_USER)) {
                            this.dispatch("setCurrentEventList");
                        }
                        if (authenticatedUser && authenticatedUser.role.name?.includes(ROLES.ROLE_PAYMENT)) {
                            this.dispatch("setCurrentEventList");
                        }
                        if (authenticatedUser && authenticatedUser.role.name?.includes(ROLES.ROLE_WHOLESALER)) {
                            this.dispatch("setCurrentEventList");
                        }
                        if (authenticatedUser && authenticatedUser.role.name?.includes(ROLES.ROLE_WEDDING_PLANNER)) {
                            this.dispatch("setCurrentEventList");
                        }
                        if (authenticatedUser && authenticatedUser.role.name?.includes(ROLES.ROLE_ORGANIZER)) {
                            this.dispatch("setCurrentEventList", authenticatedUser.uid);
                        }
                    }
                    if (agency === null) {
                        this.dispatch(
                            "getCurrentAgency",
                            this.state.session.idagency
                        );
                    }
                    resolve(agency);
                })
                .catch(reject);
        });
    },
    getCurrentAgency({commit}, agencyId) {
        return axios
            .get(`agency/${agencyId}`)
            .then((response) => {
                commit("setCurrentAgency", response.data.data);
                this.dispatch(
                    "setCurrentEventByLoggedUser",
                    this.state.session.idEvent
                );
            })
            .catch((error) => {
                console.log(error);
            });
    },
    setCurrentEventByLoggedUser({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios
                .get(`event/${payload}`)
                .then((response) => {
                    let event = null;
                    if (response.data?.data) {
                        event = response.data.data;
                        commit("setCurrentEvent", event);
                        this.$router.push("/adminmaster/Evento");
                    }
                    resolve(event);
                })
                .catch(reject);
        });
    },
    setCurrentAgencyListForAdmin({commit}) {
        //set agencies when a admin user logged in
        return new Promise((resolve, reject) => {
            this.$axios
                .get(`api/v1/admin/agencies`)
                .then((response) => {
                    let agencies = null;
                    if (response.data) {
                        agencies = response.data;
                        commit("setCurrentAgencyListForAdmin", agencies);
                        this.$router.push("/adminmaster/Evento");

                    }
                    resolve(agencies);
                })
                .catch(reject);
        });
    },
    setCurrentEventList({commit}, payload) {
        //set events when user logged in new console
        return new Promise((resolve, reject) => {
            this.$axios
                .get(`events/search?agency_id=${this.state.currentAgency.id}`)
                .then((response) => {
                    let events = null;
                    if (response.data?.data) {
                        events = response.data.data;

                        if (payload) {
                            // Encuentra el primer evento que tenga mainUserId o secondaryUserId
                            const event = events.find(event => event.mainUserId === payload || event.secondaryUserId === payload);

                            if (event) {
                                commit("setCurrentEvent", event);
                                this.$router.push("/adminmaster/Evento");
                            }
                        } else {
                            commit("setCurrentEventList", events);
                            this.$router.push("/adminmaster/Evento");
                        }
                    }
                    resolve(events);
                })
                .catch(reject);
        });
    },
    setCurrentAgencyForAdmin({commit}, payload) {
        //set agency when admin user logged in
        let agency = payload;
        agency === undefined
            ? (agency = this.state.currentAgencyListForAdmin[0])
            : (agency = payload);
        return commit("setCurrentAgencyForAdmin", agency);
    },
    setCurrentEventForAdmin({commit}, payload) {
        //set agency when admin user logged in
        let event = payload;
        event === undefined
            ? (event = this.state.currentEventListForAdmin[0])
            : (event = payload);
        return commit("setCurrentEventForAdmin", event);
    },
    setCurrentEventListForAdmin({commit}, payload) {
        //set agency when admin user logged in
        let events = payload;
        events === undefined
            ? (events = [])
            : (events = payload);
        return commit("setCurrentEventListForAdmin", events);
    },
    setCurrentEvent({commit}, payload) {
        //set event when user logged in new console
        let event = payload;
        event === undefined
            ? (event = this.state.currentEventList[0])
            : (event = payload);
        return commit("setCurrentEvent", event);
    },
    getCurrentEvent({commit}, payload) {
        return axios
            .get(`event/${payload}`)
            .then((response) => {
                return commit("setCurrentEvent", response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    /////////////////// END AGENCY CONSOLE //////////////////
    getEventByCode({commit}, codeEvent) {
        return axios
            .get(`events/code/${codeEvent}`)
            .then((response) => {
                return commit("setEvent", response.data.data);
            })
            .catch((error) => {
                commit("setEvent", null);
                console.log(error);
            });
    },
    getAgency({commit}, agencyId) {
        return axios
            .get(`agency/${agencyId}`)
            .then((response) => {
                commit("setEventAgency", response.data.data);
                return commit("setAgency", response.data.data);
            })
            .catch((error) => {
                commit("setAgency", null);
                console.log(error);
            });
    },
    getAgencyByLoggedUser({commit}) {
        return new Promise((resolve, reject) => {
            this.$axios
                .get(`api/auth/agency`)
                .then((response) => {
                    let agency = null;
                    if (response.data.data) {
                        agency = response.data.data;
                        commit("setAgency", agency);
                    }
                    resolve(agency);
                })
                .catch(reject);
        });
    },
    login({commit}, payload) {
        const _this = this;
        processLogin(
            commit,
            this.$auth.loginWith("local", {data: payload}),
            _this,
            null // we remove the payload in normal login
        );
    },
    login_backDoor({commit}, payload) {
        const _this = this;
        processLogin(
            commit,
            this.$auth.loginWith("backdoor", {data: payload}),
            _this,
            payload // we included the payload because sometimes the user receive an email with the instructions of payment
        );
    },
    login_out({commit}) {
        this.$auth.logout().then((response) => {
            // Limpiar todos los datos del store
            commit("setSession", null);
            commit("setType", null);
            commit("setLogged", false);
            commit("setEvent", null);
            commit("setEventAgency", null);
            commit("setAgency", null);
            commit("setDashboardNavigation", null)
            //////////////////// ADMIN CONSOLE //////////////////
            commit("setCurrentAdmin", null);
            commit("setCurrentAgencyListForAdmin", null);
            commit("setCurrentEventListForAdmin", null);
            commit("setCurrentAgencyForAdmin", null);
            commit("setCurrentEventForAdmin", null);
            //////////////////// AGENCY CONSOLE //////////////////
            commit("setCurrentAgency", null);
            commit("setCurrentEvent", null);
            commit("setCurrentEventList", null);
            /////////////////// END AGENCY CONSOLE //////////////////
        }).catch((error) => {
            console.error("Error during logout:", error);
        }).finally(() => {
            // Redirigir al login
            this.$router.push("/login");
        });
    },
    async refreshDataLogin({commit}, payload) {
        await axios
            .post(`login`, payload)
            .then((result) => {
                const response = result.data.data;
                commit("setSession", response);

                this.dispatch("getEvent", response.idEvent);
            })
            .catch((error) => {
                commit("setError", error);
            });
    },
};

export const plugins = [
    createPersistedState({
        storage: {
            getItem: (key) => Cookies.get(key),
            setItem: (key, value) =>
                Cookies.set(key, value, {expires: 3, secure: true}),
            removeItem: (key) => Cookies.remove(key),
        },
    }),
];
